@use '@angular/material' as mat;

$font-stack: 'Raleway';
$font-stack-title: 'Raleway';
$font-stack-header: 'Raleway';

$primary: (
  50: #e6f5f6,
  100: #c1e7e9,
  200: #98d7da,
  300: #6fc7cb,
  400: #50bbbf,
  500: #31afb4,
  600: #2ca8ad,
  700: #259fa4,
  800: #1f969c,
  900: #13868c,
  A100: #c0fbff,
  A200: #8df8ff,
  A400: #5af5ff,
  A700: #41f4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$accent: (
  50: #effbf7,
  100: #d7f4ea,
  200: #bdeddc,
  300: #a3e6ce,
  400: #8fe0c4,
  500: #7bdbb9,
  600: #73d7b2,
  700: #68d2aa,
  800: #5ecda2,
  900: #4bc493,
  A100: #ffffff,
  A200: #ebfff7,
  A400: #b8ffe1,
  A700: #9fffd7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$primary-palette: mat.define-palette($primary);
$accent-palette: mat.define-palette($accent);
$warn-palette: mat.define-palette(mat.$red-palette);

$app-color-primary: mat.get-color-from-palette($primary-palette);
$app-color-primary-light: #edf6e6;
$app-color-primary-dark: #386f85;
$app-color-blue: #0074c8;
$app-color-gray: #717171;
$app-color-gray-light: #f5f5f5;
$app-color-accent: mat.get-color-from-palette($accent-palette);
$app-color-warn: mat.get-color-from-palette($warn-palette);
$app-color-warn-pink: #df6b62;
$app-color-icon-gray: rgba(0, 0, 0, 0.7);

$breakpoint-xs: 599px;
$breakpoint-sm: 959px;
$breakpoint-md: 1279px;
$breakpoint-lg: 1919px;
