@import 'theme';
@import 'margins';
@import 'material-overrides';
@import 'image-upload.scss';
@import 'file-upload.scss';
@import 'toolbar';
@import 'grid-tile';

body {
  font-family: $font-stack;
  margin: 0;
  height: 100vh;
}

.page-content {
  padding: 20px;
}

a,
.link {
  color: $app-color-primary;
}

h1,
h2,
h3,
h4 {
  color: $app-color-primary-dark;
}

.filters {
  mat-label {
    color: white !important;
  }
  .mat-form-field-underline,
  .mat-form-field-ripple {
    background-color: white !important;
  }
  .string-operands input.mat-chip-input {
    width: 100%;
    flex: 1 0 100px;
  }
  .mat-select-value {
    color: white;
  }

  .mat-select-arrow {
    color: white;
  }
  mat-icon.menu-arrow {
    width: 16px !important;
  }

  .mat-datepicker-toggle-default-icon {
    color: white;
  }
}

.mat-form-field .mat-chip {
  min-height: 20px;
  font-size: 12px;
  padding: 0 6px 0 12px !important;
}

.no-padding .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.text-center {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.font-weight-bold {
  font-weight: bold;
}

.save-status-menu.mat-menu-panel {
  max-width: 320px;
}
