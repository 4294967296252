@import 'variables';

/* Snackbar */
snack-bar-container.snack-bar-error button {
  color: $app-color-warn;
}

/* Form Fields */
mat-form-field {
  width: 100%;
}

/* Buttons */
button.mat-button,
button.mat-raised-button,
button.mat-flat-button,
a.mat-button,
a.mat-raised-button,
a.mat-flat-button {
  &:not(.mat-icon-button) {
    border-radius: 50px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
}

/* Nav */
.nav-list .mat-ripple-element {
  background-color: rgba($app-color-accent, 0.2) !important;
}

/* Toolbar */
mat-toolbar {
  padding-left: 50px !important;
}

@media screen and (max-width: $breakpoint-xs) {
  .mat-expansion-panel-header .mat-content {
    flex-direction: column;
  }
}

/* Slide Toggle */
.mat-slide-toggle .mat-slide-toggle-label .mat-slide-toggle-content {
  white-space: normal;
}
