@import 'variables';

:not(.upload-flex-height):not(.upload-short-height):not(.upload-no-height) > .thumbnail-upload {
  margin-left: 5px;
  height: 280px;
  width: 280px;
  border: 2px dashed #aaa;
  border-radius: 5px;
  cursor: pointer;
}

.disabled .thumbnail-upload {
  cursor: auto !important;
}

.upload-flex-height .thumbnail-upload .content {
  height: 100% !important;
}

.upload-short-height .thumbnail-upload,
.upload-flex-height .thumbnail-upload {
  height: 140px;
  width: 140px;
  margin-left: 0;
  border: 2px dashed #aaa;
  border-radius: 5px;
  cursor: pointer;

  .content {
    width: 140px;
    display: inline-flex;

    .image-container {
      width: 100%;

      img {
        margin: -4px;
      }
    }

    .photo-upload-text {
      top: 10px;
      font-size: 120%;
    }
  }
}

.upload-no-height .thumbnail-upload .content {
  height: 0 !important;
}

.disabled .thumbnail-upload {
  cursor: auto;
}

.image-container {
  position: relative;
}

.photo-upload-text {
  left: 0;
  right: 0;
  top: 80px;
  text-align: center;
  font-size: 130%;
  color: $app-color-primary;
  position: absolute;
  z-index: -1;
  padding: 20px;
}

.disabled .thumbnail-upload .photo-upload-text {
  color: #aaa;
}

.foster-photo {
  z-index: 1;
  margin: auto;
  width: 100%;

  img {
    z-index: 1;
    border-radius: 5px;
    width: 105%;
    margin: -7px;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }
}

:not(.disabled) .foster-photo img:hover {
  opacity: 0.1;
}

.disabled .foster-photo img:hover {
  opacity: 1 !important;
}
