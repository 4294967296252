.grid-tile {
  position: relative;
  padding-bottom: 100%;

  &:not(.disabled) {
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
}
