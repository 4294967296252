@import 'variables';

.toolbar {
  background-color: white;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  padding: 0 20px 0 40px;
  height: 62px;
  position: sticky;
  top: 0;
  z-index: 100;

  .header {
    font-size: 120%;
    letter-spacing: 0.05em;
    color: $app-color-primary-dark;
    font-weight: bold;
    padding-left: 15px;
  }

  input {
    outline: none;
    border: none;
    width: 100%;
    margin-left: 10px;

    &::placeholder {
      font-style: italic;
      font-family: $font-stack;
    }
  }

  .filter-button {
    margin-left: 20px;
  }

  .search-icon {
    color: $app-color-primary-dark;
  }

  button.mat-button:not(.mat-icon-button) {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.toolbar + mat-progress-bar {
  margin-top: -4px;
}
