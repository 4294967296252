@import 'variables';

.document-upload .mat-dialog-container {
  padding: 0;
}

.file-upload {
  border: 2px dashed #aaa;
  border-radius: 5px;
  cursor: pointer;
  padding: 30px 20px;
}

.disabled .file-upload {
  cursor: auto;
}

.file-upload-text {
  color: $app-color-primary;
  width: 100%;
  text-align: center;
}

.disabled .file-upload .file-upload-text {
  color: #aaa;
}

.file-upload-drop-zone {
  height: 200px;
}
