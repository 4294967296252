.top-0 {
  margin-top: 0;
}
.top-10 {
  margin-top: 10px;
}
.top-20 {
  margin-top: 20px;
}
.top-30 {
  margin-top: 30px;
}
.top-40 {
  margin-top: 40px;
}
.top-50 {
  margin-top: 50px;
}
.bottom-0 {
  margin-bottom: 0;
}
.bottom-5 {
  margin-bottom: 5px;
}
.bottom-10 {
  margin-bottom: 10px;
}
.bottom-20 {
  margin-bottom: 20px;
}
.bottom-30 {
  margin-bottom: 30px;
}
.bottom-40 {
  margin-bottom: 40px;
}
.bottom-50 {
  margin-bottom: 50px;
}

.button-margin-left {
  margin-left: 8px !important;
}

.button-margin-right {
  margin-right: 8px !important;
}
